import {
  Heading,
  Flex,
  Tooltip,
  Link,
  chakra,
  Skeleton,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react';
import _debounce from 'lodash/debounce';
import React, { useCallback } from 'react';

import useIsMobile from 'lib/hooks/useIsMobile';
import IconSvg from 'ui/shared/IconSvg';
import LinkInternal from 'ui/shared/LinkInternal';

type BackLinkProp =
  | { label: string; url: string }
  | { label: string; onClick: () => void };

type Props = {
  title: string;
  className?: string;
  backLink?: BackLinkProp;
  beforeTitle?: React.ReactNode;
  afterTitle?: React.ReactNode;
  contentAfter?: React.ReactNode;
  secondRow?: React.ReactNode;
  isLoading?: boolean;
  isTextAd?: boolean;
  tabindex?: number;
};

const TEXT_MAX_LINES = 1;

const BackLink = (props: BackLinkProp & { isLoading?: boolean }) => {
  if (!props) {
    return null;
  }

  if (props.isLoading) {
    return (
      <Skeleton
        boxSize={6}
        display="inline-block"
        borderRadius="base"
        mr={2}
        my={2}
        verticalAlign="text-bottom"
        isLoaded={!props.isLoading}
      />
    );
  }

  const icon = (
    <IconSvg
      name="arrows/east"
      boxSize={7}
      transform="rotate(180deg)"
      margin="auto"
      color="#8F959E"
    />
  );

  if ('url' in props) {
    return (
      <Tooltip label={props.label}>
        <LinkInternal display="inline-flex" href={props.url} h="40px" mr={2}>
          {icon}
        </LinkInternal>
      </Tooltip>
    );
  }

  return (
    <Tooltip label={props.label}>
      <Link display="inline-flex" onClick={props.onClick} h="40px" mr={2}>
        {icon}
      </Link>
    </Tooltip>
  );
};

const PageTitle = ({
  title,
  contentAfter,
  backLink,
  className,
  isLoading,
  afterTitle,
  beforeTitle,
  secondRow,
}: Props) => {
  const titleColor = useColorModeValue('#1a1a1a', '#ffffff');
  const {
    isOpen,
    onOpen: handleOpen,
    onClose: handleClose,
    onToggle: handleToggle,
  } = useDisclosure();
  const isMobile = useIsMobile();
  const [isTextTruncated, setIsTextTruncated] = React.useState(false);

  const headingRef = React.useRef<HTMLHeadingElement>(null);
  const textRef = React.useRef<HTMLSpanElement>(null);

  const updatedTruncateState = React.useCallback(() => {
    if (!headingRef.current || !textRef.current) {
      return;
    }

    const headingRect = headingRef.current.getBoundingClientRect();
    const textRect = textRef.current.getBoundingClientRect();
    if ((TEXT_MAX_LINES + 1) * headingRect.height <= textRect.height) {
      setIsTextTruncated(true);
    } else {
      setIsTextTruncated(false);
    }
  }, []);

  React.useEffect(() => {
    if (!isLoading) {
      updatedTruncateState();
    }
  }, [isLoading, updatedTruncateState]);

  React.useEffect(() => {
    const handleResize = _debounce(updatedTruncateState, 1000);
    window.addEventListener('resize', handleResize);

    return function cleanup() {
      window.removeEventListener('resize', handleResize);
    };
  }, [updatedTruncateState]);

  const handleClick = useCallback(() => {
    if (isMobile) {
      return undefined;
    }
    handleToggle;
  }, [handleToggle, isMobile]);

  return (
    <Flex className={className} flexDir="column" rowGap={3} mb={6}>
      <Flex
        flexDir="row"
        flexWrap="wrap"
        rowGap={3}
        columnGap={3}
        alignItems="center"
      >
        <Flex
          h={{ base: 'auto', lg: isLoading ? 10 : 'auto' }}
          maxW="100%"
          alignItems="center"
        >
          {backLink && <BackLink {...backLink} isLoading={isLoading} />}
          {beforeTitle}
          <Skeleton isLoaded={!isLoading} overflow="hidden">
            <Tooltip
              label={title}
              isOpen={isOpen}
              onClose={handleClose}
              maxW={{ base: 'calc(100vw - 32px)', lg: '500px' }}
              closeOnScroll={!!isMobile}
              isDisabled={!isTextTruncated}
            >
              <Heading
                ref={headingRef}
                as="h1"
                size="lg"
                whiteSpace="normal"
                wordBreak="break-all"
                color={titleColor}
                fontWeight={700}
                style={{
                  WebkitLineClamp: TEXT_MAX_LINES,
                  WebkitBoxOrient: 'vertical',
                  display: '-webkit-box',
                }}
                textOverflow="ellipsis"
                onMouseEnter={handleOpen}
                onMouseLeave={handleClose}
                onClick={handleClick}
              >
                <span ref={textRef}>{title}</span>
              </Heading>
            </Tooltip>
          </Skeleton>
          {afterTitle}
        </Flex>
        {contentAfter}
      </Flex>
      {secondRow && (
        <Flex alignItems="center" minH={10} overflow="hidden">
          {secondRow}
        </Flex>
      )}
    </Flex>
  );
};

export default chakra(PageTitle);
